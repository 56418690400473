import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import orderBy from 'lodash/orderBy'
import moment from 'moment'

function defaultState() {
  return {
    voteWindowInterval: null,
    loadedMessages: {},
    loadedMessagesByRoom: {},
    newestRoomMessage: '',
    dayVotes: {},
    myVotes: {},
    votesValidUntil: null,
    unreadMessages: 0,
    newestMessage: '',
    loadingImages: 0,
    autoScroll: true,
    imageModal: {
      imgSrc: '',
      text: '',
      nickname: '',
    },
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {}

function validRealm(message, store) {
  const messageRealm = message.realm
  const messageRoom = message.room
  if (store.getters.shouldMergeInterAndPdt() && !message.trade) {
    if (store.state.realms.activeRealm === 'inter' && messageRealm === 'pdt' && messageRoom === 'main') {
      message.realm = 'inter'
      message.room = 'sala-inter'
      return true
    }
  }
  if (messageRealm !== store.state.realms.activeRealm) return false

  return true
}
function activeRoom(store) {
  return store.state.rooms.activeRoom
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  receiveVotes(state, votes) {
    const store = this.app.store

    if (typeof votes === 'number') {
      state.votesValidUntil = votes
      return
    }
    votes.forEach((vote) => {
      if (vote.realm !== store.state.realms.activeRealm) return
      if (state.votesValidUntil !== vote.validUntil) {
        state.votesValidUntil = vote.validUntil
        state.dayVotes = { [vote.validUntil]: {} }
        state.myVotes = { [vote.validUntil]: {} }
      }
      if (moment().valueOf() > state.votesValidUntil) state.votesValidUntil += 24 * 60 * 60 * 1000
      if (!state.dayVotes[vote.validUntil]) state.dayVotes = { [vote.validUntil]: {} }
      if (!state.myVotes[vote.validUntil]) state.myVotes = { [vote.validUntil]: {} }
      if (!state.dayVotes[vote.validUntil][vote.votedId]) state.dayVotes[vote.validUntil][vote.votedId] = 0
      if (!vote.oldSide) vote.oldSide = 0
      state.dayVotes[vote.validUntil][vote.votedId] += vote.side - vote.oldSide
      if (store.state.auth.user && vote.voterId === store.state.auth.user._id) {
        if (vote.side) state.myVotes[vote.validUntil][vote.votedId] = vote
        else delete state.myVotes[vote.validUntil][vote.votedId]
      }
    })
    state.dayVotes = { ...state.dayVotes }
    state.myVotes = { ...state.myVotes }
  },
  receiveMessages(state, messages) {
    const store = this.app.store
    const currentRoom = activeRoom(store)

    messages.forEach((message) => {
      if (!validRealm(message, this.app.store)) return
      if (message.realm === 'pdt' && Object.keys(message).includes('trade')) return
      state.loadedMessages[message._id] = message
      if (!state.loadedMessagesByRoom[message.room]) state.loadedMessagesByRoom[message.room] = {}
      state.loadedMessagesByRoom[message.room][message._id] = message
      if (!state.autoScroll && message.room === currentRoom && message._id > state.newestRoomMessage) state.unreadMessages++
      if ((message.room === currentRoom && message._id > state.newestRoomMessage) || !state.newestRoomMessage) state.newestRoomMessage = message._id

      if (!state.autoScroll) return
      const orderedMsgs = orderBy(state.loadedMessagesByRoom[message.room], '_id')
      while (orderedMsgs.length > 100) {
        delete state.loadedMessagesByRoom[orderedMsgs[0]._id]
        orderedMsgs.shift()
      }
    })
    state.loadedMessages = { ...state.loadedMessages }
    state.loadedMessagesByRoom = { ...state.loadedMessagesByRoom }
  },
  removeMessage: (state, messageId) => {
    // Vue.delete(state.loadedMessages, messageId)
    const rooms = Object.keys(state.loadedMessagesByRoom)
    rooms.forEach((r) => {
      if (state.loadedMessagesByRoom[r][messageId]) Vue.delete(state.loadedMessagesByRoom[r], messageId)
    })
  },
  toggleAutoScroll: (state, autoScroll) => {
    if (autoScroll) state.unreadMessages = 0
    state.autoScroll = autoScroll
  },
  hideNewMessages: (state, room) => {
    const visibleMessages = orderBy(state.loadedMessagesByRoom[room], '_id').filter((message) => !message.hidden)
    while (visibleMessages.length > 100) {
      const { _id: msgId } = visibleMessages.pop()
      state.loadedMessagesByRoom[room][msgId].hidden = true
    }
    state.loadedMessagesByRoom = { ...state.loadedMessagesByRoom }
  },
  unhideMessages: (state, params) => {
    const { amount, room } = params
    const orderedMsgs = orderBy(state.loadedMessagesByRoom[room], '_id')
    const length = amount || orderedMsgs.length
    orderedMsgs
      .filter((message) => message.hidden)
      .slice(0, length)
      .forEach((message) => (state.loadedMessagesByRoom[room][message._id].hidden = false))

    state.loadedMessagesByRoom = { ...state.loadedMessagesByRoom }
  },
  removeOldMessages: (state, params) => {
    let { amount } = params
    const { room } = params
    const orderedMsgs = orderBy(state.loadedMessagesByRoom[room], '_id')
    if (!amount) amount = 100
    if (amount >= orderedMsgs.length) return
    state.loadedMessagesByRoom[room] = orderedMsgs.slice(orderedMsgs.length - amount, orderedMsgs.length).reduce((obj, item) => {
      obj[item._id] = item
      return obj
    }, {})
  },
  clearRoomMessages: (state, room) => {
    if (state.loadedMessagesByRoom[room]) state.loadedMessagesByRoom[room] = {}
    state.newestRoomMessage = ''
  },
}
