import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'

import timeLimitedAck from '~/assets/js/timeLimitedAck'

function defaultState() {
  return {
    data: {},
    btnState: 'ready',
    isConfirmationModalOpen: false,
    isDisabled: false,
    symbol: '',
    side: 1, // 1: buy, -1: sell
    symbolNickname: null,
    type: 'start', // start, limit, auction
    auctionSide: null,
    auctionDate: null,
    percentage: 100,
    triggerPrice: 0, // Entrada
    limitPrice: 0, // old entryPrice // Limite
    partialTargetPrice: 0, // Alvo Parcial
    targetPrice: 0, // Alvo
    stopPrice: 0, // Stop
    setDelayAlert: false,

    assetList: [],
    priceStep: 0.1,
    percentStep: 5,
    symbolQuote: null,

    pressed: {
      shift: 10,
      ctrl: 100,
      alt: 0.1,
    },

    lightning: {
      portfolioSlug: '',
      percentage: 100,
      type: 'limit-buy',
      price: 0,
      stop: 0,
      partial: 0,
      target: 0,
      relativeStop: 0,
      relativePartial: 0,
      relativeTarget: 0,
      entryOffset: 0,
      exitOffset: 0,
      relativeExits: false,
      priceConfig: {
        decimal: ',',
        thousands: '.',
        suffix: '',
        precision: 2,
        masked: false,
      },
      cookie: {
        lastSymbols: {},
        relativeExits: {
          bovespaIndex: false,
          dollar: false,
          other: false,
        },
        relativeStop: {
          bovespaIndex: 0,
          dollar: 0,
          other: 0,
        },
        relativePartial: {
          bovespaIndex: 0,
          dollar: 0,
          other: 0,
        },
        relativeTarget: {
          bovespaIndex: 0,
          dollar: 0,
          other: 0,
        },
        entryOffset: {
          bovespaIndex: 0,
          dollar: 0,
          other: 0,
        },
        exitOffset: {
          bovespaIndex: 0,
          dollar: 0,
          other: 0,
        },
        percentage: {
          bovespaIndex: 100,
          dollar: 100,
          other: 100,
        },
        type: {
          bovespaIndex: 'limit-buy',
          dollar: 'limit-buy',
          other: 'limit-buy',
        },
      },
    },

    mirroring: {
      type: 'portfolio',
      fromPortfolio: null,
      fromTrade: null,
    },

    moneyConfig: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false,
      min: -10,
    },

    percentageConfig: {
      decimal: ',',
      thousands: '.',
      suffix: ' %',
      precision: 2,
      masked: false,
    },

    confirmationBoxOptions: {
      id: 'confirmation_modal',
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'Sim',
      cancelTitle: 'Não',
      centered: true,
      footerClass: 'confirmation-footer flex-column-reverse',
      autoFocusButton: 'ok',
    },
  }
}

export const state = defaultState

export const getters = { getField }

export const actions = {
  async safetyMechanism({ state }, { type, isPrivate }) {
    try {
      if (state.lightning.isConfirmationModalOpen) return
      state.lightning.isConfirmationModalOpen = true
      const confirmation = await window.$nuxt.$bvModal.msgBoxConfirm(`Publicar ${isPrivate ? 'Ideia de trade' : 'Recomendação'}?`, state.confirmationBoxOptions)
      if (!confirmation) return
      this.commit('forms/createNewTrade', type)
    } catch (err) {
      console.log(err)
    } finally {
      state.lightning.isConfirmationModalOpen = false
    }
  },
  async getAssets({ state }) {
    try {
      if (state.symbol.length <= 2) return
      const res = await this.$axios.get(`/api/instruments?text=${state.symbol}`)
      state.assetList = res.data
    } catch (err) {
      console.log(err)
    }
  },
  getQuote({ state }, symbol) {
    if (symbol.length > 4) {
      this.$cedro.emit('subscribe', { symbol })
      this.$cedro.emit(
        'live-quote',
        { symbol },
        timeLimitedAck(this.$cedro, (err, quote) => {
          if (err) state.symbolQuote = {}
          else {
            const asset = state.assetList[0]
            if (!asset) return
            state.symbolQuote = quote
            state.lightning.price = quote.last
            state.priceStep = asset.step
            state.moneyConfig.precision = asset.decimalPlaces
            state.lightning.stop = 0
            state.lightning.partial = 0
            state.lightning.target = 0
            state.lightning.relativeStop = 0
            state.lightning.relativePartial = 0
            state.lightning.relativeTarget = 0
            state.lightning.percentage = 0
            state.lightning.type = 'limit-buy'
            try {
              const portfolioId = this.app.store.state.portfolios.idBySlug[state.lightning.portfolioSlug]

              if (!state.lightning.cookie.lastSymbols[portfolioId]) state.lightning.cookie.lastSymbols[portfolioId] = [symbol]
              else {
                const symbolIndex = state.lightning.cookie.lastSymbols[portfolioId].indexOf(symbol)
                if (symbolIndex > -1) state.lightning.cookie.lastSymbols[portfolioId].splice(symbolIndex, 1)
                state.lightning.cookie.lastSymbols[portfolioId].unshift(symbol)
                if (state.lightning.cookie.lastSymbols[portfolioId].length > 6)
                  state.lightning.cookie.lastSymbols[portfolioId] = state.lightning.cookie.lastSymbols[portfolioId].slice(0, 6)
              }

              const isBovespaIndex = /^(WIN|IND)\w\d\d/.test(symbol)
              const isDollar = /^(WDO|DOL)\w\d\d/.test(symbol)

              Object.keys(state.lightning.cookie).forEach((parameter) => {
                if (parameter === 'lastSymbols') return
                state.lightning[parameter] = state.lightning.cookie[parameter][isBovespaIndex ? 'bovespaIndex' : isDollar ? 'dollar' : 'other']
              })

              this.$cookies.set('cdi_boleta_lightning', state.lightning.cookie)
            } catch (err) {}
          }
        }),
      )
    }
  },
  saveBoletaCookie({ state }, parameter) {
    try {
      if (['stop', 'partial', 'target'].includes(parameter)) return
      const symbol = state.symbol
      if (!symbol) return
      const isBovespaIndex = /^(WIN|IND)\w\d\d/.test(symbol)
      const isDollar = /^(WDO|DOL)\w\d\d/.test(symbol)

      state.lightning.cookie[parameter][isBovespaIndex ? 'bovespaIndex' : isDollar ? 'dollar' : 'other'] = state.lightning[parameter]

      this.$cookies.set('cdi_boleta_lightning', state.lightning.cookie)
    } catch (err) {}
  },
  async zeroTrade({ state }, { tradeId, percentage, price, market }) {
    try {
      if (state.isConfirmationModalOpen) return
      state.isConfirmationModalOpen = true
      const confirmationMsg = market
        ? 'Deseja enviar saída a mercado?'
        : 'Deseja enviar saída no último preço negociado? Para sair à mercado, segure o Shift ao clicar no percentual desejado.'
      const confirmation = await window.$nuxt.$bvModal.msgBoxConfirm(confirmationMsg, state.confirmationBoxOptions)
      if (!confirmation) return
      state.setDelayAlert = true
      window.$nuxt.$emit('processing', tradeId)
      window.$nuxt.$emit('trade-admin-tool-show-form', { mode: 'closeForm' })
      const data = {
        _id: tradeId,
        exit: {
          price,
          tradePercentage: percentage || 1,
          market,
        },
      }
      this.$socket.emit(
        'request-zero-trade',
        data,
        timeLimitedAck(this.$socket, (err) => {
          state.setDelayAlert = false
          if (err) throw err
          window.$nuxt.$emit('processing-done', tradeId)
        }),
      )
    } catch (err) {
      console.log(err)
      window.$nuxt.$emit('processing-fail', tradeId)
    } finally {
      state.isConfirmationModalOpen = false
    }
  },
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, defaultState())
  },
  step(state, data) {
    if (event) event.preventDefault()
    const val = _.get(state, data.key)
    _.set(state, data.key, val + data.step * data.direction)
  },
  wheel(state, data) {
    // const event = data.event
    // event.preventDefault()
    // const delta = event.deltaY || event.deltaX
    // const direction = -(delta / Math.abs(delta))
    // const step = ['percentage', 'lightning.percentage'].includes(data.key) ? state.percentStep : state.priceStep
    // const modifier = (event.altKey ? state.pressed.alt : 1) * (event.ctrlKey ? state.pressed.ctrl : 1) * (event.shiftKey ? state.pressed.shift : 1)
    // this.commit('forms/step', { key: data.key, step: step * modifier, direction }, event)
  },
  createNewTrade(state, type) {
    state.btnState = 'spinning'

    state.data = {}
    state.data.entryPercentage = state.lightning.percentage / 100
    state.data.portfolioSlug = state.lightning.portfolioSlug
    state.data.symbol = state.symbol
    if (type === 'limit-buy') {
      state.data.side = 1
      state.data.entryType = 'limit'
      state.data.entryPrice = state.lightning.price
    } else if (type === 'limit-sell') {
      state.data.side = -1
      state.data.entryType = 'limit'
      state.data.entryPrice = state.lightning.price
    } else if (type === 'start-buy') {
      state.data.side = 1
      state.data.entryType = 'start'
      state.data.entryPrice = state.lightning.price
      state.data.triggerPrice = state.data.entryPrice
      state.data.limitPrice = state.data.entryPrice + state.lightning.entryOffset
    } else if (type === 'start-sell') {
      state.data.side = -1
      state.data.entryType = 'start'
      state.data.entryPrice = state.lightning.price
      state.data.triggerPrice = state.data.entryPrice
      state.data.limitPrice = state.data.entryPrice - state.lightning.entryOffset
    } else if (type === 'market-buy') {
      state.data.side = 1
      state.data.entryType = 'market'
      state.data.entryPrice = this.app.store.state.trades.rawQuotes[state.symbolQuote.symbol].last
      state.data.market = true
    } else if (type === 'market-sell') {
      state.data.side = -1
      state.data.entryType = 'market'
      state.data.entryPrice = this.app.store.state.trades.rawQuotes[state.symbolQuote.symbol].last
    }

    if (state.lightning.relativeExits) {
      if (state.lightning.relativeStop) {
        state.data.stopPrice = state.lightning.price - state.data.side * state.lightning.relativeStop
        state.data.stopLimitPrice = state.data.stopPrice - state.lightning.exitOffset * state.data.side
      }
      if (state.lightning.relativePartial) state.data.partialTargetPrice = state.lightning.price + state.data.side * state.lightning.relativePartial
      if (state.lightning.relativeTarget) state.data.targetPrice = state.lightning.price + state.data.side * state.lightning.relativeTarget
    } else {
      if (state.lightning.stop) {
        state.data.stopPrice = state.lightning.stop
        state.data.stopLimitPrice = state.lightning.stop - state.lightning.exitOffset * state.data.side
      }
      if (state.lightning.partial) state.data.partialTargetPrice = state.lightning.partial
      if (state.lightning.target) state.data.targetPrice = state.lightning.target
    }

    state.data.thesis = {}

    state.setDelayAlert = true

    this.$socket.emit(
      'request-create-waiting-trade',
      { ...state.data },
      timeLimitedAck(this.$socket, (err) => {
        try {
          if (err) throw err
          this.commit('forms/operationSuccess')
        } catch (err) {
          this.commit('forms/operationFail', err)
        } finally {
          state.setDelayAlert = false
        }
      }),
    )
  },
  operationSuccess(state) {
    state.btnState = 'done'
    setTimeout(() => {
      window.$nuxt.$emit('trade-admin-tool-show-form', { mode: 'closeForm' })
      state.btnState = 'ready'
    }, 1000)
  },
  operationFail(state, err) {
    console.log(err)
    state.btnState = 'error'
    state.isDisabled = false
    setTimeout(() => (state.btnState = 'ready'), 1000)
  },
}
