var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.portfoliosLoaded)?_c('LoadingScreen'):_c('div',{staticClass:"template-wrapper",on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[(
      _vm.inOwnInstances() &&
      _vm.isMobile &&
      _vm.autoplay &&
      !['aovivo', 'canal-roomSlug', 'minha-assinatura'].includes(_vm.$route.name) &&
      (!_vm.socketWentRogue || !_vm.cedroWentRogue)
    )?_c('LiveStreamStripe',{attrs:{"is-mobile":_vm.isMobile,"autoplay":_vm.autoplay}}):_vm._e(),_vm._v(" "),(_vm.socketWentRogue || _vm.cedroWentRogue)?_c('RefreshStripe'):_vm._e(),_vm._v(" "),(_vm.$activeRealm === 'pdt')?_c('PdtNavbar'):(_vm.$activeRealm === 'inter')?_c('InterNavbar'):_c('Navbar'),_vm._v(" "),(_vm.$route.path === '/aovivo' && _vm.pdtBanner.enabled)?_c('PdtBanner'):_vm._e(),_vm._v(" "),_c('b-container',{staticClass:"d-flex px-0 mx-lg-auto",class:[`${_vm.$activeRealm}-container`, _vm.$activeRealm === 'inter' && _vm.$route.name !== 'canal-roomSlug' && 'mb-5 mb-xl-0'],attrs:{"fluid":""}},[(_vm.$activeRealm !== 'pdt')?_c('portfolio-sidebar'):_vm._e(),_vm._v(" "),_c('nuxt',{attrs:{"id":"cdi_container"}})],1),_vm._v(" "),_c('ModalsController'),_vm._v(" "),(['cdi'].includes(_vm.$activeRealm))?_c('FloatingVideo',{attrs:{"show-floating-video":_vm.showFloatingVideo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }